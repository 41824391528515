import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, share, takeWhile, timer } from 'rxjs';
import { MfaTransport } from '../../dto/ask-mfa.payload';

@Component({
  selector: 'app-resend-timer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './resend-timer.component.html',
  styleUrls: ['./resend-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResendTimerComponent {
  private cd = inject(ChangeDetectorRef);

  @Input() seconds = 5;
  @Output() resendClick = new EventEmitter<MfaTransport>();
  allowResend = false;
  transports = MfaTransport;

  timeRemaining$ = this.getTimer();

  private getTimer() {
    return timer(0, 1000)
      .pipe(
        map((n) => {
          if (this.seconds - n === 0) {
            this.allowResend = true;
          }
          return n;
        })
      )
      .pipe(
        map((n) => (this.seconds - n) * 1000),
        takeWhile((n) => n >= 0),
        share()
      );
  }

  resend(transport: MfaTransport) {
    this.allowResend = false;
    this.resendClick.emit(transport);
    this.timeRemaining$ = this.getTimer();
    this.cd.detectChanges();
  }
}
