<div class="resend-timer">
  @if (!allowResend) {
    <div>Не отримали код? Відправити повторно через:</div>
  }
  @if (!allowResend) {
    <div class="timer">
      <span class="timer-icon"></span>
      <span class="countdown">{{ timeRemaining$ | async | date:'mm:ss' }}</span>
    </div>
  }

  @if (allowResend) {
    <div class="resend">Надіслати повторно через:
      <span (click)="resend(transports.SMS)">SMS</span> / <span (click)="resend(transports.EMAIL)">Email</span>
    </div>
  }
</div>
