<div class="background">
  <div class="bg"></div>
</div>

<div class="auth-page">
  <div class="placeholder"></div>
  <form class="form" [formGroup]="loginForm" (keydown.enter)="submit()">
    <div class="logo"></div>
    @if (!maxAttemptsReached()) {
      <div class="title">{{ mfaPayload() ? 'Код підтвердження' : 'Вхід' }}</div>
    }
    @if (maxAttemptsReached()) {
      <div class="title error">Перевищено кількість спроб 🤨</div>
    }

    @if (!mfaPayload() && !maxAttemptsReached()) {
      <div class="subheader">Введіть дані свого користувача</div>
    }
    @if (maxAttemptsReached()) {
      <div class="subheader">Зачекайте якийсь час та спробуйте знову ⌛. Або зверніться до
        адміністратора системи чи вашого керівника.
      </div>
    } @else {
      @if (mfaPayload() && maskedDestination()) {
        <div class="subheader">
          Повідомлення з кодом надіслано на <span class="dest">{{ maskedDestination() }}</span>.<span
          class="info"
          matTooltip="Щоб змінити номер або email - зверніться до адміністратора системи або вашого керівника."></span>
        </div>
      }

      @if (!mfaPayload()) {
        <div class="input-holder">
          <mat-form-field appearance="outline">
            <mat-label>Логін</mat-label>
            <input autofocus matInput formControlName="login">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Пароль</mat-label>
            <input matInput type="password" formControlName="password">
          </mat-form-field>
        </div>
      }

      @if (mfaPayload(); as payload) {
        <code-input
          #codeInput
          [ngClass]="{'invalid': badCode()}"
          [isCodeHidden]="false"
          [codeLength]="payload.mfaOptions.codeLength"
          (codeChanged)="onCodeChanged($event)"
          (codeCompleted)="onCodeCompleted($event)"
        >
        </code-input>
      }

      @if (badCode()) {
        <div class="bad-code">
          Невірний код. Спробуйте ще раз
        </div>
      }

      @if (!forceResend()) {
        @if (mfaPayload(); as payload) {
          <app-resend-timer
            (resendClick)="resendCode($event)"
            [seconds]="payload.mfaOptions.attemptsInterval"
          >
          </app-resend-timer>
        }
      }

      <ngx-turnstile
        [siteKey]="siteKey"
        (resolved)="sendCaptchaResponse($event)"
        lang="uk-ua"
        theme="light"
      >
      </ngx-turnstile>

      @if (!mfaPayload()) {
        <div
          [ngClass]="{ disabled: !loginForm.valid || loading() || !recaptchaTokenSet()}"
          (click)="submit()"
          matRipple class="login-button"
        >
          {{ !loading() ? 'Далі' : 'Перевіряю дані...' }}
        </div>
      } @else {
        <div
          [ngClass]="{ disabled: !recaptchaTokenSet() || !codeSet()}"
          (click)="submit()"
          matRipple class="login-button"
        >
          {{ 'Увійти' }}
        </div>
      }
    }

    @if (loading()) {
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    }
  </form>
  <div class="info">
    <span class="excl"></span>Виникли проблеми з входом? Зверніться до вашого керівника
  </div>

</div>
