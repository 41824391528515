import { Component, DestroyRef, inject, OnInit, signal, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { AuthResponseType } from './dto/auth-res';
import { AskMfaPayload, MfaTransport } from './dto/ask-mfa.payload';
import { AlertService } from '../shared/services/alert.service';
import { environment } from '../../environments/environment';
import { CodeInputComponent } from 'angular-code-input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  loading = signal(false);
  siteKey = environment.turnstileSiteKey;
  maskedDestination = signal('');
  badCode = signal(false);
  forceResend = signal(false);
  mfaPayload = signal<AskMfaPayload | null>(null);
  maxAttemptsReached = signal(false);
  recaptchaTokenSet = signal(false);
  codeSet = signal(false);

  loginForm = new FormGroup({
    login: new FormControl('', [Validators.required, Validators.minLength(4)]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    pin: new FormControl(''),
    cfToken: new FormControl(null, [Validators.required]),
    transport: new FormControl<MfaTransport>(MfaTransport.SMS, [
      Validators.required,
    ]),
  });

  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService
  ) {
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/news']);
    }
    this.loginValueHandler();
  }

  sendCaptchaResponse(captchaResponse: any) {
    this.loginForm.controls.cfToken.setValue(captchaResponse);
    this.recaptchaTokenSet.set(true);
  }

  onCodeChanged(_: string): void {
    this.badCode.set(false);
  }

  onCodeCompleted(code: string): void {
    this.loginForm.controls.pin.setValue(code);
    this.codeSet.set(true);
  }

  resendCode(transport: MfaTransport): void {
    this.loginForm.controls.transport.setValue(transport);
    this.maskedDestination.set('');
    this.badCode.set(false);
    this.forceResend.set(true);
    this.codeSet.set(false);
    this.codeInput.reset();
    this.submit();
  }

  resetTurnstile(): void {
    this.loginForm.controls.cfToken.setValue(null);
    this.recaptchaTokenSet.set(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.turnstile.reset();
  }

  submit(): void {
    if (this.loading() || !this.loginForm.valid) {
      return;
    }

    if (this.mfaPayload() && !this.loginForm.value.pin && !this.forceResend) {
      return;
    }

    this.loading.set(true);
    this.forceResend.set(false);

    this.authService
      .login(
        this.loginForm.value.login || '',
        this.loginForm.value.password || '',
        this.loginForm.value.cfToken || '',
        this.loginForm.value.pin || '',
        this.loginForm.value.transport || ''
      )
      .subscribe({
        next: (res) => {
          this.loading.set(false);
          this.codeSet.set(false);
          this.resetTurnstile();

          switch (res.type) {
            case AuthResponseType.SUCCESS:
              window.location.href = '/'; // Need to full reload page
              break;
            case AuthResponseType.MFA_CODE_MAX_ATTEMPTS:
              this.maxAttemptsReached.set(true);
              break;
            case AuthResponseType.MFA_CODE_GENERATION_ERROR:
              this.alertService.showError(res.data);
              break;
            case AuthResponseType.INVALID_CAPTCHA:
              this.alertService.showError(res.data);
              break;
            case AuthResponseType.MFA_CODE_INVALID:
              this.badCode.set(true);
              this.loginForm.controls.pin.setValue('');
              break;
            case AuthResponseType.MFA_REQUIRED:
              this.mfaPayload.set(res.data);
              this.maskedDestination.set(this.mfaPayload()!.maskedDestination);
              break;
            default:
              break;
          }
        },
        error: (_) => {
          this.alertService.showError(
            'Помилка авторизації. Перевірте логін та пароль.'
          );
          this.resetTurnstile();
          this.loading.set(false);
          this.codeSet.set(false);
        },
      });
  }

  private loginValueHandler(): void {
    this.loginForm.controls.login.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const login = this.loginForm.controls.login.value;
        if (login && login.length > 0) {
          this.loginForm.controls.login.setValue(login.trim().toLowerCase(), { emitEvent: false });
        }
      });
  }
}
