export interface AskMfaPayload {
  message: string;
  transport: MfaTransport;
  maskedDestination: string;
  mfaOptions: MfaOptions;
}

export interface MfaOptions {
  maxAttempts: number;
  attemptsInterval: number;
  codeLength: number;
}

export enum MfaTransport {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}
